export default {
  computed: {
    faye() {
      const faye = new Faye.Client(Window.faye_url, {
        retry: 5
      })
      return faye
    }
  },
  methods: {
    fayeSubscribe(channel, context, callback) {
      //this.faye.addExtension(new FayeAuthentication(this.faye));
      this.faye.subscribe(channel).withChannel(callback)
       console.log('FAYE', context, "subscribed to", channel)
    },
    fayeUnsubscribe(channel, context) {
      this.faye.unsubscribe(channel)
      // console.log('FAYE', context, "unsubscribed from", channel)
    }
  }
}
